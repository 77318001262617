import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useAuthState } from "react-firebase-hooks/auth";
import { useGoogleAuth } from "../App";
import firebase from "firebase/app";
import "firebase/auth";
import ImportDocLogo from "./ImportDocLogo";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flex: 1,
    color: "#000",
    textDecoration: "none",
  },
  toolbar: {},
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  logo: {
    verticalAlign: -5,
  },
  footer: {
    padding: theme.spacing(5, 2),
    marginTop: "auto",
  },
}));

export default function () {
  const authService = firebase.auth();
  const classes = useStyles();
  const [user] = useAuthState(authService);
  const { signOut } = useGoogleAuth();
  async function handleSignOut() {
    firebase.auth().signOut();
    signOut();
  }
  return (
    <Toolbar className={classes.toolbar}>
      <Typography
        component={(props) => <RouterLink {...props} to="/" />}
        variant="h6"
        className={classes.title}
      >
        <ImportDocLogo dark={true} />
      </Typography>
      {!user && (
        <>
          <Link
            color="inherit"
            noWrap
            variant="body2"
            className={classes.toolbarLink}
            component={RouterLink}
            to="/"
          >
            Home
          </Link>
        </>
      )}
      {user && (
        <>
          <Link
            color="inherit"
            noWrap
            variant="body2"
            className={classes.toolbarLink}
            component={RouterLink}
            to="/documents"
          >
            Documents
          </Link>
          <Link
            color="inherit"
            noWrap
            variant="body2"
            className={classes.toolbarLink}
            component={RouterLink}
            to="/account"
          >
            Account
          </Link>
          <Link
            color="inherit"
            noWrap
            variant="body2"
            className={classes.toolbarLink}
            onClick={handleSignOut}
          >
            Log Out
          </Link>
        </>
      )}
    </Toolbar>
  );
}
