import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { applyPolyfills, defineCustomElements } from "import-doc/loader";

const rootElement = document.getElementById("root");
render(<App />, rootElement);

applyPolyfills().then(() => {
  defineCustomElements();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
