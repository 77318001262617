import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";

export default function () {
  return (
    <Grid container spacing={4} justify="space-evenly">
      <Grid item xs={12} sm={6} md={2} key={1}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Legal
        </Typography>
        <ul>
          <li key={1}>
            <Link
              color="textSecondary"
              variant="subtitle1"
              component={RouterLink}
              to="/privacy"
            >
              Privacy
            </Link>
          </li>
          <li key={2}>
            <Link
              color="textSecondary"
              variant="subtitle1"
              component={RouterLink}
              to="/terms"
            >
              Terms & Conditions
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={6} md={2} key={2}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Social
        </Typography>
        <ul>
          <li key={0}>
            <Link
              color="textSecondary"
              variant="subtitle1"
              href="https://github.com/importdoc"
            >
              GitHub
            </Link>
          </li>
          <li key={1}>
            <Link
              color="textSecondary"
              variant="subtitle1"
              href="https://twitter.com/import_doc"
            >
              Twitter
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={6} md={2} key={3}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Docs
        </Typography>
        <ul>
          <li key={0}>
            <Link
              color="textSecondary"
              variant="subtitle1"
              component={RouterLink}
              to="/developers"
            >
              Developers
            </Link>
          </li>
          <li key={1}>
            <Link
              color="textSecondary"
              variant="subtitle1"
              noWrap
              component={RouterLink}
              to="/wordpress"
            >
              WordPress
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={6} md={2} key={4}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Contact
        </Typography>
        <ul>
          <li key={1}>
            <Link
              color="textSecondary"
              variant="subtitle1"
              href="mailto:contact@importdoc.com"
            >
              Email
            </Link>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
}
