import React, { useEffect, Suspense, lazy } from "react";
import { CLIENT_ID, SCOPES, FIREBASE_CONFIG } from "./config";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import useGoogle from "./hooks/useGoogle";
import LinearProgress from "@material-ui/core/LinearProgress";

import firebase from "firebase/app";
import "firebase/auth";

firebase.initializeApp(FIREBASE_CONFIG);
const authService = firebase.auth();

const Home = lazy(() => import("./pages/Home"));
const Documents = lazy(() => import("./pages/Documents"));
const Document = lazy(() => import("./pages/Document"));
const Account = lazy(() => import("./pages/Account"));
const NoMatch = lazy(() => import("./pages/NoMatch"));
const WordPress = lazy(() => import("./pages/WordPress"));
const Developers = lazy(() => import("./pages/Developers"));
const Terms = lazy(() => import("./pages/Terms"));
const Privacy = lazy(() => import("./pages/Privacy"));

export const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flex: 1,
    color: "#000",
    textDecoration: "none",
  },
  toolbar: {},
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  logo: {
    verticalAlign: -5,
  },
  footer: {
    padding: theme.spacing(5, 2),
    marginTop: "auto",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  heroContent: {
    padding: theme.spacing(2, 0, 4),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  inputRoot: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  progress: {
    margin: theme.spacing(1),
  },
  centeredGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

interface PrivateRouteProps {
  component: any;
  path?: string;
}

const GoogleAuthContext = React.createContext<any>(null);

export const GoogleAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const googleAuth = useGoogle({
    clientId: CLIENT_ID,
    uxMode: "popup",
    scope: SCOPES.join(" "),
    persist: true,
  });

  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {/* The rest of your app */}
      {children}
    </GoogleAuthContext.Provider>
  );
};

export const useGoogleAuth = () => React.useContext(GoogleAuthContext);

export const PrivateRoute = ({
  component,
  user,
  ...rest
}: PrivateRouteProps & { user?: firebase.User }) => {
  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps): React.ReactNode => {
    if (user) {
      return <Component user={user} {...props} />;
    }
    return <Redirect to={{ pathname: "/" }} />;
  };

  return <Route {...rest} render={render} />;
};

function App() {
  const classes = useStyles({});
  const [user, loading] = useAuthState(authService);
  useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = "dbe4d306-7aec-4b22-b42d-0e02143cb42b";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  const theme = responsiveFontSizes(
    createMuiTheme({
      palette: {
        primary: { main: "#b5b5b5" },
        secondary: { main: "#252525" },
        type: "light",
      },
      typography: {
        fontFamily: "'Roboto', sans-serif",
      },
    })
  );

  return (
    <GoogleAuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
          {loading ? (
            <LinearProgress />
          ) : (
            <Suspense fallback={<LinearProgress />}>
              <Router>
                <Container maxWidth="md">
                  <Menu />
                  <Switch>
                    <Route
                      path="/"
                      exact
                      render={(props) => <Home {...props} user={user} />}
                    />
                    <Route path="/privacy/" component={Privacy} />
                    <Route path="/terms/" component={Terms} />
                    <Route path="/wordpress/" component={WordPress} />
                    <Route path="/developers/" component={Developers} />
                    <PrivateRoute
                      path="/documents/"
                      component={Documents}
                      user={user}
                    />
                    <PrivateRoute
                      path="/account/"
                      component={Account}
                      user={user}
                    />
                    <PrivateRoute
                      path="/document/:id"
                      component={Document}
                      user={user}
                    />
                    <Route component={NoMatch} />
                  </Switch>
                </Container>
                <Container
                  maxWidth="md"
                  component="footer"
                  className={classes.footer}
                >
                  <Footer />
                </Container>
              </Router>
            </Suspense>
          )}
        </div>
      </ThemeProvider>
    </GoogleAuthProvider>
  );
}

export default App;
