const stable_config = {
  apiKey: "AIzaSyBp68UxR9LFeQcXVuuMnvIarKVUhsNfNbo",
  authDomain: "importdoc.com",
  databaseURL: "https://importdoc-63eb5.firebaseio.com",
  projectId: "importdoc-63eb5",
  storageBucket: "importdoc-63eb5.appspot.com",
  messagingSenderId: "30823479197",
  appId: "1:30823479197:web:de74ba2a8aacb659",
};

const canary_config = {
  apiKey: "AIzaSyBAoyWCr2Ow7VLIY2_qJpZqz7vFTEbOWCc",
  authDomain: "importdoc-canary.firebaseapp.com",
  databaseURL: "https://importdoc-canary.firebaseio.com",
  projectId: "importdoc-canary",
  storageBucket: "importdoc-canary.appspot.com",
  messagingSenderId: "629120274014",
  appId: "1:629120274014:web:53e7c811b9392637",
};

export const isDev = process.env.NODE_ENV === "development";
export const SERVICE_EMAIL = process.env.REACT_APP_SERVICE_EMAIL as string;
export const APP_ID = process.env.REACT_APP_APP_ID as string;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
export const DEVELOPER_ID = process.env.REACT_APP_DEVELOPER_ID as string;
export const FIREBASE_CONFIG =
  process.env.REACT_APP_PROJECT_ID === "importdoc-canary"
    ? canary_config
    : stable_config;

export const SCOPES = ["https://www.googleapis.com/auth/drive.file"];
export const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];
export const SERVICE_API = isDev
  ? "http://localhost:5005"
  : process.env.REACT_APP_PROJECT_ID === "importdoc-canary"
  ? "https://importdoc-canary-api.web.app"
  : "https://api.importdoc.com";
export const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";
export const IMPORTDOC_COMPONENT_URL = isDev
  ? "http://localhost:3333/build/import-doc.js"
  : "https://unpkg.com/import-doc/dist/import-doc/import-doc.js";
export const FUNCTIONS_EMULATOR_DEV = "http://localhost:5001";

// Pages
export const PAGE_DEVELOPERS = process.env.REACT_APP_PAGE_DEVELOPERS as string;
export const PAGE_WORDPRESS = process.env.REACT_APP_PAGE_WORDPRESS as string;
export const PAGE_TERMS = process.env.REACT_APP_PAGE_TERMS as string;
export const PAGE_PRIVACY = process.env.REACT_APP_PAGE_PRIVACY as string;
